<template>
  <div class="tears">
    <h3 style="padding-top: 0.2rem;">散瞳</h3>
    <div style="width: 90%; margin: 0 auto">
      <h4 style="padding-top: 40px">次数</h4>
      <ul class="cs">
        <li>
          <span>第一次：</span>
          <el-time-picker
              value-format="HH:mm:ss"
              arrow-control
              v-model="postdata.S_OP_Json.S_OP_ST_santongTime1"
              :picker-options="{}"
              placeholder="任意时间点">
          </el-time-picker>
        </li>
        <li>
          <span>第二次：</span>
          <el-time-picker
              value-format="HH:mm:ss"
              arrow-control
              v-model="postdata.S_OP_Json.S_OP_ST_santongTime2"
              :picker-options="{}"
              placeholder="任意时间点">
          </el-time-picker>
        </li>
        <li>
          <span>第三次：</span>
          <el-time-picker
              arrow-control
              value-format="HH:mm:ss"
              v-model="postdata.S_OP_Json.S_OP_ST_santongTime3"
              :picker-options="{}"
              placeholder="任意时间点">
          </el-time-picker>
        </li>
      </ul>
    </div>

<!--    <h4 style="padding-top: 40px">泪分</h4>-->
    <AppearsTest></AppearsTest>
  </div>
</template>

<script>
export default {
  name: "tears",
  data() {
    return {
      input: '',
      postdata: {
        S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
        S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
        S_OP_ExpertId: this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'00000000-0000-0000-0000-000000000000',
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json:{
          S_OP_IO_ChooseId: this.$store.state.physicianVisits.xzConsulting,
          S_OP_ST_santongTime1: '',
          S_OP_ST_santongTime2: '',
          S_OP_ST_santongTime3: ''
        },
        S_OP_Model: "S_OP_Santong230216",
        S_OP_Type: "S_OP_Santong"
      },
    }
  },
  watch: {
    "postdata": {
      deep: true,
      handler(n, o) {
        // console.log(n)
        this.$store.commit('physicianVisits/upmydriasis', n)
      }
    }
  },
  created() {
    this.$store.commit('physicianVisits/upmydriasis', this.postdata)
    this._http.post(this._getUrl('PS_all_Normal_getList'), {
      "token": {
        "TimeSpan": 1566827733,
        "sToken": "768DCAFFF869CD61372790C2738012EC"
      },
      SearchCondition: {
        S_OP_Type: 'S_OP_Santong',
        ChooseId: this.$store.state.physicianVisits.xzConsulting
      }
    })
    .then(res => {
      if (res.GetListResult) {
        this.postdata.S_OP_Json = this._clJson(res.GetListResult[0].S_OP_Json)
      }
    })
  },
  components: {
    AppearsTest: () => import('@/views/specialSurvey/appearsTest'),
  }
}
</script>

<style scoped lang="scss">
.tears {
  //width: 90%;
  margin: 0 auto;
}
h4 {
  text-align: left;
  padding-bottom: 10px;
}
.cs {
  padding: 15px 0;
  li {
    display: flex;
    padding-bottom: 10px;
    justify-content: start;
    align-items: center;
  }
}
.nr {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-bottom: 20px;
  .name {
    padding: 0 10px;
    padding-left: 20px;
  }
  .sr {
    width: 200px;
    margin-right: 5px;
  }
  p {
    padding-right: 20px;
  }
}
</style>
